<!--
 * @ Author: Lmi
 * @ Create Time: 2023-09-04 15:00:55
 * @ Modified by: Your name
 * @ Modified time: 2023-09-04 15:00:55
 * @ Description: 数据统计>平台统计>充电排行统计 
 -->

<template>
  <scroll-layout>
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div class="charging-ranking-statistics">
      <ykc-table
        ref="YkcTable"
        operateFixedType="right"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns">
        <template #headerButtons>
          <div class="table-header-button">
            <template v-for="(btn, i) in pageButtons">
              <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handleClick">
                {{ btn.text }}
              </ykc-button>
            </template>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.size"
          :current-page.sync="pageInfo.current"
          @size-change="handlePaginationSizeChange"
          @current-change="handlePaginationCurrentChange"></ykc-pagination>
      </ykc-table>
    </div>
  </scroll-layout>
</template>

<script>
  import { offlineExport, code, getLastTime, localGetItem } from '@/utils';
  import { dataStatisticsServe, barrierPage, directPage } from '@/service/apis';

  export default {
    name: 'chargingRankingStatisticsList',

    data() {
      return {
        pageButtons: [
          {
            id: '0',
            text: '导出',
            type: 'plain',
            enabled: () => code('charging:ranking:export'),
            handleClick: () => {
              this.doExportFile();
            },
          },
        ],
        tableData: [],
        tableColumns: [
          { label: '序号', prop: 'id', minWidth: '150px' },
          { label: '电站名称', prop: 'stationName', minWidth: '150px' },
          { label: '运营商', prop: 'operator', minWidth: '150px' },
          { label: '充电信息', prop: 'chargingValue', minWidth: '150px' },
        ],
        tableTitle: '明细列表',
        pageInfo: {
          current: 1,
          size: 10,
        },
        dictionary: {},
        stationData: [], // 电站数据
        OperatorData: [], // 运营商数据
        searchParams: {
          date: [getLastTime('yyyy-MM-dd', 30), getLastTime('yyyy-MM-dd', 0)],
          queryStartDate: getLastTime('yyyy-MM-dd', 30),
          queryEndDate: getLastTime('yyyy-MM-dd', 0),
          stationId: '',
          operatorId: '',
          type: 'ELECTRICAL',
        },
      };
    },
    created() {
      const dictionary = localGetItem('dictionary');
      if (dictionary?.ranking_filter_type) {
        this.dictionary = dictionary.ranking_filter_type.map(item => ({
          id: item.code,
          name: item.name,
        }));
      }
      this.getOperatorList();
      this.getStationData();
      this.searchTableList();
    },
    computed: {
      searchData() {
        return [
          {
            label: '时间维度',
            key: 'date',
            comName: 'YkcDatePicker',
            change: this.handleChange,
          },
          {
            comName: 'YkcDropdown',
            key: 'stationId',
            label: '电站名称',
            placeholder: '请输入电站名称',
            data: this.stationData,
            remote: true,
          },
          {
            comName: 'YkcDropdown',
            key: 'operatorId',
            label: '运营商',
            placeholder: '请输入运营商',
            data: this.OperatorData,
            remote: true,
          },
          {
            comName: 'YkcDropdown',
            key: 'type',
            label: '筛选维度',
            clearable: false,
            data: [...this.dictionary],
          },
        ];
      },
    },
    methods: {
      handleChange(event, data) {
        console.log('event:', event);
        console.log('data:', data);
        this.searchParams.queryStartDate = event?.date[0];
        this.searchParams.queryEndDate = event?.date[1];
      },
      /**
       * @desc 获取电站数据
       * */
      getStationData() {
        barrierPage.findStationList({}).then(res => {
          this.stationData = (res || []).map(item => {
            return {
              id: item.stationId,
              name: item.stationName,
            };
          });
        });
      },
      /**
       * 获取运营商数据
       */
      getOperatorList() {
        directPage
          .userOperatorList({ keyword: '' })
          .then(res => {
            res?.map(item => {
              return this.OperatorData.push({
                id: item.operatorId,
                name: item.name,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },

      /** 根据条件查询数据 */
      searchTableList() {
        dataStatisticsServe
          .chargingRankingList({
            ...this.pageInfo,
            ...this.searchParams,
          })
          .then(res1 => {
            this.tableData = res1.records;
            this.pageInfo.total = res1.total || 0;
          });
      },
      /**
       * @desc 导出文件 - 充电排行统计列表
       * */
      doExportFile() {
        offlineExport(
          {
            downloadType: 'charging_ranking_statistics',
            jsonNode: {
              ...this.searchParams,
            },
          },
          this.pageInfo.total
        );
      },
      /**
       * @desc 点击页面左上角按钮 callback
       * */
      handlePageButtonClick(button, event) {
        if (typeof button.handleClick === 'function') {
          button.handleClick(button, event);
        }
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(form) {
        this.pageInfo.current = 1;
        Object.assign(this.searchParams, form);
        this.searchTableList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form, {
          type: 'ELECTRICAL',
          date: [getLastTime('yyyy-MM-dd', 30), getLastTime('yyyy-MM-dd', 0)],
          queryStartDate: getLastTime('yyyy-MM-dd', 30),
          queryEndDate: getLastTime('yyyy-MM-dd', 0),
        });
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.searchTableList();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.searchTableList();
      },
    },
  };
</script>
<style lang="scss" scoped></style>
